import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div>
      <div className="section">
        <h1>Not Found...</h1>
        <p>Sorry, you seem to have found a page that doesn't exist.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
